/* ---------------------------carousel------------------------------ */
.body2 {
  margin-left: 10%;
  margin-right: 10%;
}
.title1 {
  text-align: center;
  font-size: 1cm;
  font-family: monospace;
  margin: 3%;
  font-weight: 500;
}
.carousel {
  z-index: 80;
  margin-top: -3%;
  margin-bottom: 5%;
}

@media (max-width: 500px) {
  /* carusela */
  .body2 {
    margin-left: 5%;
    margin-right: 5%;
  }
  .carousel {
    height: 500px;
    margin-top: -20%;
    margin-bottom: 20%;
  }
  .title1 {
    text-align: center;
    font-size: 1cm;
    font-family: monospace;
    margin-top: 10%;
  }
}

/* ---------------------------carousel------------------------------ */

/* ---------------------------footer------------------------------ */
.body-footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: right;
  font-size: large;
  background-color: rgb(238, 238, 238);
}
.image-footer {
  width: "50%";
  float: "center";
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: "50%";
}
.logo-footer {
  width: 50%;
}
.details-contact-footer {
  margin-right: 5%;
}
.details-contact-footer p {
  margin-bottom: 2px; /* You can adjust the value as needed */
  text-align: right;
  align-items: center;
  justify-content: center;
}
.title-footer {
  text-align: center;
  font-weight: 500;
  margin: 5%;
  font-size: 30px;
}
@media (max-width: 500px) {
  .logo-footer {
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
    margin-bottom: 50px;
  }
  .body-footer {
    flex-direction: column-reverse;
  }

  .row-noa {
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .details-contact-footer {
    text-align: center;
    font-size: medium;
    margin: 0; /* Remove default margin for better centering */
    margin-bottom: 25px;
  }
  .details-contact-footer p {
    text-align: center;
  }
  .follow {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    font-size: medium;
  }
}

/* ---------------------------footer------------------------------ */

/* ---------------------------images------------------------------ */
.link-insta {
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.link-insta:hover {
  visibility: visible;
  opacity: 0.5;
}
@media (max-width: 500px) {
  .one-photo {
    width: 200;
    background-color: black;
  }
  .link-insta {
    display: block;
  }
  .link-insta:hover {
    visibility: none;
  }
}
/* ---------------------------images------------------------------ */

/* ---------------------------ModelBox------------------------------ */

.form-contact {
  margin-right: 3%;
  margin-bottom: 5%;
}
/* ---------------------------ModelBox------------------------------ */

/* ---------------------------oneProduct------------------------------ */

.card-products {
  margin: 10%;
  border: none;
  border-color: white;
}
.details-card {
  margin-top: 8%;
}
.title-product {
  font-size: medium;
  height: 40px;
}
.des-product {
  font-size: 0.4cm;
}
.price-product {
  color: rgb(162, 162, 162);
}

@media (max-width: 500px) {
  .card-products {
    margin: -7%;

    border: none;
    border-color: white;
  }
  .card-img {
    height: 200px;
    /* width: fit-content; */
  }

  .card-products {
    display: inline;
  }
}

/* ---------------------------oneProduct------------------------------ */

/* ---------------------------socialMedia------------------------------ */

.social {
  justify-content: space-between;
  padding: 2%;
}

.social-item:hover {
  visibility: visible;
  opacity: 0.5;
}
/* ---------------------------socialMedia------------------------------ */
/* -----------------------------Recommend---------------------------------- */

.carousel-rec {
  z-index: 80;
  margin-top: -8%;
  margin-bottom: 7%;
}

.div-card-rec {
  margin-left: 2%;
  margin-right: 2%;
}
@media (max-width: 500px) {
  .carousel-rec {
    height: 500px;
    margin-top: -50%;
    margin-bottom: 20%;
  }
  .div-card-rec {
    margin-left: 2%;
    margin-right: 2%;
  }
}
