/* ----------------------------------------------------collection------------------------- */
.collection-page {
  margin-top: 15ch;
}

.container1 {
  margin-top: 10ch;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  background-color: rgb(242, 237, 237);
}

.title {
  text-align: center;
  font-size: 1cm;
  font-family: "Identidad", sans-serif;
  margin: 3%;
}

.collection {
  margin-left: 10%;
  margin-right: 10%;
}

@media (max-width: 500px) {
  .collection {
    margin-right: 3%;
    margin-left: 3%;
  }
}

/* ----------------------------------------------------collection------------------------- */

/* ----------------------------------------------------home------------------------- */
.home {
  margin-top: 5ch;
}

.des-top {
  text-align: center;
  font-size: medium;
  font-family: Helvetica, Arial, sans-serif;
  margin: 3%;
  color: rgb(111, 110, 110);
}

.body-carosel {
  justify-content: center;
  align-items: center;
}

.card-about {
  font-size: large;
  /* background-color: rgb(240, 238, 238); */
  /* background-image: url(https://i.imagesup.co/images2/5bea857585fe8c4a3182ebfc70ff5c5c89c1abfc.jpg);
  background-repeat: no-repeat;
  background-size: cover; */
  margin-top: 3%;
  background-color: white;
  margin-right: auto;
  flex-direction: row-reverse;
}
.text-about {
  text-align: center;
  padding-right: 0%;
  padding-left: 0%;
  text-align: center;
}
.read-more {
  justify-content: center;
  align-items: center;
  padding: 2%;
}
.card-service {
  font-size: large;
  /* background-color: rgb(255, 255, 255); */
  /* background-image: url(https://i.imagesup.co/images2/5bea857585fe8c4a3182ebfc70ff5c5c89c1abfc.jpg);
  background-repeat: no-repeat;
  background-size: cover; */
  margin-right: auto;
  background-color: white;
}
.text-service {
  text-align: center;
  padding-right: 0%;
  padding-left: 0%;
}
.read-more-service {
  justify-content: center;
  align-items: center;
  padding: 2%;
}
@media (max-width: 1024px) {
  .body-carosel {
    justify-content: center;
    align-items: center;
  }
  .card-about {
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
  }
  .image-about {
    height: 200px;
  }
  .text-about {
    text-align: right;
  }
  .read-more {
    justify-content: center;
    align-items: center;
    padding: 2%;
    padding-inline-start: 70%;
  }

  .card-service {
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
  }
  .image-service {
    height: 200px;
  }
  .text-service {
    text-align: right;
  }
  .read-more-service {
    justify-content: center;
    align-items: center;
    padding: 2%;
    padding-inline-start: 70%;
  }
}

/* ----------------------------------------------------home------------------------- */

/* ----------------------------------------------------Gallery------------------------- */
.gallery-page {
  margin-top: 15ch;
}
.gallery {
  margin-top: 5%;
  margin-left: 15%;
  margin-right: 15%;
}

@media (max-width: 500px) {
  .gallery {
    margin-top: 10%;
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
}
/* ----------------------------------------------------Gallery------------------------- */

/* ----------------------------------------------------contact------------------------- */

.contact-page {
  margin-top: 15ch;
}

.row-contact {
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 2%;
}
.map-contact {
  margin-top: 5%;
  margin-left: 2%;
}
.follow-text {
  text-align: center;
  font-size: large;
  font-family: Helvetica, Arial, sans-serif;
  margin: 3%;
  color: rgb(111, 110, 110);
}

@media (max-width: 500px) {
  .details-contact {
    margin-top: 5%;
    text-align: center;
  }
}
/* ----------------------------------------------------contact------------------------- */

/* ----------------------------------------------------cart------------------------- */

.number-details {
  padding: 2%;
  margin-top: 4%;
  margin-right: 4%;
}

.title-cart {
  font-size: medium;
  padding: 2%;
  margin-top: 4%;
  margin-right: 4%;
}

.body-cart {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* overflow-y: auto; */
  overflow-y: "scroll";
  margin-bottom: 50%;
}

.name-cart {
  font-size: small;
  display: flex;
  font-weight: 500;
}
.image-cart {
  width: 100%;
}

.quantity-cart {
  background-color: rgb(243, 243, 243);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20%;
}
.quantity {
  background-color: rgb(243, 243, 243);
  border-color: rgb(243, 243, 243);
  border-radius: 0%;
}

.remove-cart {
  color: rgb(148, 149, 150);
  font-size: small;
  margin-right: 10%;
  text-align: center;
  display: flex;
  cursor: pointer;
  text-decoration-line: underline;
  justify-content: center;
  align-items: center;
}
.sum-div {
  background-color: rgb(243, 243, 243);
  position: sticky;
  bottom: 0px;
  /* padding: 10px; */
  position: fixed;
  width: fit-content;
  margin-top: 10%;
  z-index: 80%;
  padding-left: 100%;
  padding-bottom: 2%;
}
.sum-cart {
  font-size: medium;
  display: flex;
  flex-direction: row;
  column-gap: 16ch;
  margin: 5%;
}

.btn-cart {
  justify-content: center;
}

@media (max-width: 500px) {
  .image-cart {
    width: 100%;
  }
  .sum-div {
    background-color: rgb(243, 243, 243);
    /* position: sticky;
  bottom: 0;
  padding: 10px; */
    position: fixed;
    bottom: 0;
    width: fit-content;
    z-index: 100%;
    padding-left: 100%;
    padding-bottom: 7%;
  }
  .name-cart {
    font-size: small;
    display: flex;
    font-weight: 500;
  }
  .sum-cart {
    font-size: medium;
    display: flex;
    flex-direction: row;
    column-gap: 16ch;
    margin: 5%;
  }
}
/* ----------------------------------------------------cart------------------------- */

/* ----------------------------------------------------about------------------------- */

.about {
  margin-top: 5ch;
}

.body {
  text-align: center;
}
.title-about {
  text-align: center;
  font-size: 1cm;
  font-family: "Identidad", sans-serif;
  margin-top: 3%;
}
.sub-title {
  margin-bottom: 3%;
}

.text {
  margin-left: 20%;
  margin-right: 20%;
  font-size: large;
  justify-content: space-around;
}

.contact-btn {
  margin: 2%;
}

@media (max-width: 500px) {
  .text {
    font-size: small;
  }
  .text p {
    margin-bottom: 15px; /* You can adjust the value as needed */
    font-size: 16px;
    line-height: 1.6;
    text-align: center;
    color: #333;
  }
}
/* ----------------------------------------------------about------------------------- */
