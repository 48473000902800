div#NagishLiTrigger {
  left: 10px !important;
  right: 30px;
  bottom: 5 !important;
  top: 100px;
  filter: grayscale(100%);
}

p {
  font-family: "Open Sans", sans-serif;
}
