/* navbar and message */
.navbar-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: #f5f5f5;
}

.message {
  background-color: rgb(0, 0, 0);
  color: white;
  text-align: center;
}
.logo {
  color: rgb(8, 7, 6);
  text-align: center;
  justify-content: center;
  align-content: center;
}

.card-body {
  text-align: center;
  font-size: large;
  font-weight: 400;
}
.navbar-brand {
  display: flex;
  align-items: center;
}
.type {
  height: 60;
}
/* banner */
.mask {
  display: flex;
  height: 600px;
  justify-content: flex-end;
  text-align: end;
  text-shadow: 0px 1px 1px rgb(98, 97, 97);
}
.main-title {
  font-size: 9ch;
  width: 15cm;
  text-shadow: 3px 3px 3px rgb(0, 0, 0);
}
.amit {
  text-shadow: 2px 2px 2px rgb(0, 0, 0);
}
.your-custom-navbar-class {
  height: 60px;
  text-align: center;
}

.social-item:hover {
  visibility: visible;
  opacity: 0.5;
}

@media (max-width: 600px) {
  /* navbar and message */

  .navbar-toggler {
    color: rgb(245, 241, 234);
    border: none;
  }

  .navbar1 {
    max-width: 400px;
    text-align: center;
  }
  .your-custom-navbar-class {
    height: auto;
  }

  /* banner */
  .mask {
    display: flex;
    height: 1000px;
    text-align: center;
    justify-content: center;
    text-shadow: 0px 1px 1px rgb(162, 158, 158);
  }
  .main-title {
    font-size: 3ch;
    width: auto;
  }
}
